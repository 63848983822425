body {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

.recharts-text {
    font-size: 12px;
}


.react-flow__pane .react-flow__handle {
    /*display: none !important;*/
    width: 0;
    height: 0;
    border: none;
}
